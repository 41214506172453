.dashboard-container{
outline: none
}

   
      @import './content/basic.scss';
      @import './hire/hiprofile.scss';
      @import './profile.scss';
      @import './hire/hihome.scss';
      @import './home.scss';
      @import './content/bank.scss';
      @import './content/education.scss';
      @import './content/licence.scss';
      @import './content/availability.scss';
      @import './content/subject.scss';
      @import './content/overview.scss';
      @import './hire/hibasic.scss';
      @import './hire/plan.scss';
      @import './hire/hioverview.scss';
     