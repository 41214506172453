.profile-container{
  .mobile{
    position: fixed;
    z-index: 100;
    top: 3%;
    margin-left: 2%;
    color: $secondary;
    font-size: 25px;
    display: none;
    @media only screen and (max-width: 740px) {
      display: block;  
  }
}
    .sidebar{
      z-index: 100;   
      background-color: $secondary;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      width: 18%;
      height: 100vh;
      transition:all ease-in-out 0.3s;
      @media only screen and (max-width: 740px) {
       width: 0%;
       overflow-x: hidden; 
       transition:all ease-in-out 0.5s;
    }
      .closebtn{
        color: $pwhite;
        font-size: 60px;
        text-align: right;
        margin-right: 3%;
        display: none;
        @media only screen and (max-width: 740px) {
          display: block;
       }
      }   
   .uparrow{
    position: fixed;
    left: 92%;
    bottom: 2%;
    font-size: 2.5rem;
    cursor: pointer;
    color: $primary;
    height: min-content;
  
    
    transition:all ease .3s;
    @media only screen and (max-width: 700px) {
      left: 90%;
   }
   @media only screen and (max-width: 500px) {
    left: 85%;
 }
 @media only screen and (max-width: 400px) {
  left: 80%;
}
   .hide{
    display: none;
   }
   .show{
     display: block;
   }
  }
        
        .logout-container{
      text-align: right;
     margin: 1vh;
     
          .btn-logout{
            border: none;
            padding: 5% 7%;
            font-size: 15px;
            background-color: $primary;
            color: $pwhite;
            cursor: pointer;
            transition: all ease .3s;
            &:hover{
              transition: all ease .3s;
              background-color: $secondary;
            }
          }
        }      
      }

      .content {
      margin-left: 18%;
          padding: 1% 3%;
          transition: all ease-in-out .3s;
      @media only screen and (max-width: 740px) {
        transition: all ease-in-out .3s;
        margin-left: 5%;
      }
      }

   
}