.weare-container{
   text-align: center;
    h1{
        font-size: 55px;
        color: $primary;
       
    }
    p{
      padding: 5% 10%;
        color: grey;       
    }
   
}