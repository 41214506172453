.footer-container{
    background-color: $secondary;
    color: $pwhite;
    .footer-top{
        padding-top: 3em;
        margin: 0em 5em;
        display: grid;
        grid-template-columns: 1.5fr 1.5fr 2fr;
        
        @media only screen and (max-width: 1000px) {
            margin: 2em;
        }
        @media only screen and (max-width: 900px) {
            grid-template-columns: 1fr 1fr;
            margin: .5em;
            text-align: center;
        }
        @media only screen and (max-width: 530px) {
            grid-template-columns: 1fr;
            margin: .5em;
            text-align: center;
        }
        
        .footer-top-left{
            box-sizing: border-box;
            .img{
                background-color: $pwhite;
                margin:0% 15%;
                border-radius: 80px;
                text-align: center;
                @media only screen and (max-width: 530px) {
                   display: none;
                }
                img{
                  
                    width: 100%;
                   
                }
            }
            .left-text{
                line-height: 1.5em;
            }
        }
        .footer-top-middle{
            justify-self: center;
            box-sizing: border-box;
            .contact-info{
                margin: 1em 0em;
               a{ 
                   color: $pwhite;
                   text-decoration: none;
                   &:hover{
                    color: $primary;
                }
            }
            }
        }
        .footer-top-right{
            .form-content{
               
                box-sizing: border-box;
                input{
                    padding: 0.8em;
                    border: 1px solid $secondary;
                    border-right: none;
                    border-bottom-left-radius: 10px;
                    border-top-left-radius: 10px;
                    outline: none;
                    width: 60%;
                   
                }
                button{
                    background-color: $primary;
                    color: $pwhite;
                    padding: 0.8em;
                    border: 1px solid $secondary;
                    border-left: none;
                    border-bottom-right-radius: 10px;
                    border-top-right-radius: 10px;
                    width: 30%;
                    cursor: pointer;
                    transition: all ease .5s;
                    &:hover {
                       
                        transition: all ease .5s;
                        border: 1px solid $primary;
                        border-left: none;
                        background-color: $secondary;
                        
                    }
                   
                }
            }
        }

        .social{
            margin-top: 2em;
            display: flex;
            justify-content: space-evenly;
            a{
                font-size: 2em;
                color: $primary;
                transition: all ease .5s;
                &:hover{
                    transition: all ease .5s;
                    color: $pwhite;
                }
            }
        }
        .policy{
            margin: 1em 0em;
            display: flex;
            justify-content: space-evenly;
            a{
                color: $pwhite;
                text-decoration: none;
                &:hover{
                    color: $primary;
                }
            }
        }
    }

   .hr{
        width: 100%; 
        border-bottom: 1px solid rgba(185, 185, 185, 0.26); 
        line-height: 0.1em;
        margin: 2em 0em;
     }

     .footer-bottom{
         text-align: center;
         padding-bottom: 2%;
         span{
             background-color: #0096D6;
             padding: 1px 10px;
         }
     }
}