.hihome-container{
  .mobile{
    position: fixed;
    z-index: 199;
    top: 3%;
    margin-left: 2%;
    color: $secondary;
    font-size: 25px;
    display: none;
    @media only screen and (max-width: 740px) {
      display: block;  
  }
}
    .sidebar{
      z-index: 100;   
      display: grid;
      background-color: $secondary;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      width: 18%;
      transition:all ease-in-out 0.3s;
      @media only screen and (max-width: 740px) {
       width: 0%;
       overflow-x: hidden; 
       transition:all ease-in-out 0.5s;
    }
    .closebtn{
      color: $pwhite;
      font-size: 60px;
      text-align: right;
      margin-right: 3%;
      display: none;
      @media only screen and (max-width: 740px) {
        display: block;
     }
    }
      .profile-pic{
          text-align: center;
          align-self: center;
          img{
            border-radius: 50%;
            width: 50%;
            cursor: pointer;
          }
      }
      .nav-links{
          display: flex;
          flex-direction: column;
          text-align: center;
          .nav_link{
                padding: 6% 1%;
                text-decoration: none;
                color: $primary;
                text-align: center;
                background-color: $pwhite;
                margin: 3% 10%;
                transition: all ease .3s;
                cursor: pointer;
                border: none;
                &:hover{
                    transition: all ease .3s;
                    color: $secondary;
                }
          }
         
      }
          .btn-logout{
            align-self: end;
            border: none;
            padding: 5% 7%;
            font-size: 15px;
            background-color: $primary;
            color: $pwhite;
            cursor: pointer;
            transition: all ease .3s;
            @media only screen and (max-width: 700px) {
              align-self: start;
             
           }
            &:hover{
                color: $primary;
              transition: all ease .3s;
              background-color: $pwhite;
              border: 1px solid black;
            }
          }
          .uparrow{
            position: fixed;
            left: 92%;
            bottom: 2%;
            font-size: 2.5rem;
            cursor: pointer;
            color: $primary;
            height: min-content;
            transition:all ease .3s;
            @media only screen and (max-width: 700px) {
              
              left: 90%;
           }
           @media only screen and (max-width: 500px) {
            left: 85%;
         }
         @media only screen and (max-width: 400px) {
          left: 80%;
        }
           .hide{
            display: none;
           }
           .show{
             display: block;
           }
        }
    }

      .content {
      margin-left: 18%;
      padding: 1% 3%;
      transition: all ease-in-out .3s;
      @media only screen and (max-width: 740px) {
        transition: all ease-in-out .3s;
        margin-left: 5%;
      }
        }

   
}