.availability-container{
    .form-container{
        margin: 3% 0%;
        .heading{

            display: grid;
            grid-template-columns: 1fr .8fr .8fr;
            gap: 3%;
        }
        .form-control{
            padding: 3%;
            background-color: #F0ECEC;
            border: none;
            border-radius: 10px;
            outline: none;
            transition: all ease .3s;
            cursor: pointer;
            &:focus{
                transition: all ease .3s;
                background-color: $pwhite;
                border-top: 1px solid $secondary;
            }
           

        }
        .days{
            display: grid;
            grid-template-columns: 1fr .8fr .8fr;
            gap: 3%;
           margin: 2% 0%;
           @media only screen and (max-width: 600px) {
            margin:10% 0%;
            grid-template-columns: 1fr ;
         }
            #label{
                display: grid;
                grid-template-columns: 1fr 1.5fr ;
                .check{
                    transform: scale(1.5);
                    
                }
            }
            
        }
        .btn-container{
            .btn-submit{
                padding: 1% 2.5%;
                font-size: 18px;
                background-color: $primary;
                border: none;
                cursor: pointer;
                color: $pwhite;
                transition: all ease .3s;
                &:hover{
                    transition: all ease .3s;
                    background-color: $secondary;

                }
            }
            
        }

    }
}