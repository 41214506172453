.signin-container{
  
    .signin{
       
        background: linear-gradient(to right,$secondary,$primary);
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
        height: 100vh;
        display: grid;
        place-content:center ;
        .signin-form{
            padding: 10% 10% 10% 5%;
            background-color: rgba(255,255,255,0.7);
            border-radius: 10px;
            .text{
                text-align: center;
                h1{
                    color: $primary;
                }

            }

            form{
                display: grid;
                justify-content: center;
                .form-control{
                    padding: .8em;
                    margin:2% 0%;
                    border: none;
                    border-radius: 10px;
                    background-color: $pwhite;
                    outline: none;
                    width: 100%;
                }
                .other-links{
                    display: flex;
                    justify-content: space-between;
                    gap: 1%;
                    .reset-link,.signup-link{
                        text-decoration: none;
                        cursor: pointer;
                        color: $primary;
                        transition: all ease-in-out .3s;
                        &:hover{
                            transition: all ease-in-out .3s;
                            color: $secondary;
    
                        }
                    }

                }
                
               
                .btn-container{
                    display: grid;
                    .btn-signin{
                        margin: 2% 0%;
                        justify-self: center;
                        border: none;
                        font-size: 18px;
                        padding: .6rem 2rem;
                        border-radius: 30px;
                        background-color: $primary;
                        color: white;
                        transition: all ease .3s;
                        cursor: pointer;&:hover{
                            transition: all ease .3s;
                            background-color: $secondary;
                        }
                    }
                    .bot {
                        place-self: center;
                        margin: 0% 50%;
                        border: 10px solid $pwhite;
                        border-radius: 50%;
                        border-top: 10px solid $primary;
                        width: 6%;
                        height: 6%;
                        -webkit-animation: spin 2s linear infinite;
                        animation: spin 2s linear infinite;
                      }
                }
            }
        }
    }
}
