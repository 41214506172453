.adtutorsearch-container{
    .tutor-card{
        margin: 1% 0%;
        padding: 5% 2%;
        background-color: #D2C2C5;
        border: 1px solid $secondary;
        display: grid;
        gap:5%;
        grid-template-columns: .3fr 1fr;
        .tutor-detail{
            text-transform: uppercase;
            display: grid;
            gap:5%;
            grid-template-columns: 1fr 1fr;
            color: $primary;
            overflow-wrap: break-word;
            word-break: break-all;
            .email,.phone{
            cursor: pointer;
            }
            
        }
        .tutor-image{
            place-self: center;
            
            img{
                width: 50%;
                border-radius: 50%;
            }
        }
       
    }
    .search-option{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap:3%;
        margin-bottom: 3%;
        @media only screen and (max-width: 600px) {
            grid-template-columns: 1fr ;
        }
        .form-control{
            padding: 2% 3%;
            outline: none;
            border: 1px solid $primary;
            border-radius: 10px;
            text-transform: uppercase;
            @media only screen and (max-width: 600px) {
                margin: 3% 0%;
            }
        }
    }
    .btn-container{
        display: flex;
        gap: 5%;
        .clear{
            background-color: $secondary;
            padding: .9% 4%;
            color: $pwhite;
            cursor: pointer;
        }
        .btn-search{
            border: none;
            background-color: $primary;
            padding: 1% 4%;
            color: $pwhite;
            cursor: pointer;
        }
    }
}