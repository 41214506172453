.faq-container{
    margin-top: 5%;
    h1{
        text-align: center;
    }
    .accordion{
        display: grid;
        margin: 5% 10%;
        grid-template-columns: 1fr 1fr;
        justify-content: center;
        @media only screen and (max-width: 900px) {
            grid-template-columns: 1fr;
            margin: 5% 2%;
         }
        .accordion-item{
            .accordion-title{
                border: 2px solid $primary;
                padding: .7rem;
                margin: 1%;
               color: $secondary;
               cursor: pointer;
               box-sizing: border-box;
               @media only screen and (max-width: 530px) {
                font-size: 12px;
             }
            }
            .accordion-content{
                padding: 1rem 1.3rem;
               
            }
        }
        
    }
}