.about-container {
	margin-top: -8%;
	z-index: -1;
	.about-top {
		background-image: url("../../images/about.jpg");
		background-repeat: no-repeat;
		background-position: top;
		background-size: cover;
		height: 90vh;
		z-index: -1;
		text-align: center;
		h1 {
			padding-top: 9em;
			color: $pwhite;
		}
		p {
			color: rgb(184, 184, 184);
			word-spacing: 10px;
			font-size: 18px;
			a {
				text-decoration: none;
				color: rgb(184, 184, 184);
			}
			span {
				word-spacing: 0px;
			}
		}
	}

	.about-body-1 {
		display: grid;
		grid-template-columns: 2fr 1.5fr;
		margin: 3%;
		gap: 5%;
		@media only screen and (max-width: 700px) {
			grid-template-columns: 1fr;
		}
		.body-1-left {
			h1,
			h3 {
				color: $primary;
				margin: 3% 0%;
			}
			p {
				color: grey;
				font-size: 17px;
				line-height: 20px;
			}
		}
		.body-1-right {
			@media only screen and (max-width: 400px) {
				display: none;
			}
			img {
				width: 100%;
			}
		}
	}

	.about-body-2-container {
		background-color: #f8f8fa;
		padding: 2% 0%;
		.about-body-2 {
			display: grid;
			grid-template-columns: 1.5fr 2fr;
			margin: 3%;
			gap: 5%;
			@media only screen and (max-width: 700px) {
				grid-template-columns: 1fr;
			}
			.body-2-right {
				h1,
				h3 {
					color: $primary;
					margin: 3% 0%;
				}
				div {
					span {
						align-self: center;
					}
					display: grid;
					grid-template-columns: 0.2fr 5fr;
				}
				p {
					color: grey;
					font-size: 17px;
					word-wrap: break-word;
					line-height: 20px;
				}
			}
			.body-2-left {
				@media only screen and (max-width: 700px) {
					grid-row-start: 2;
				}
				img {
					width: 100%;
				}
			}
		}
	}

	.about-body-3 {
		display: grid;
		grid-template-columns: 2fr 1.5fr;
		margin: 3%;
		gap: 5%;
		@media only screen and (max-width: 700px) {
			grid-template-columns: 1fr;
		}
		.body-3-right {
			@media only screen and (max-width: 700px) {
				margin-bottom: 10%;
			}
			img {
				width: 100%;
			}
		}
		.body-3-left {
			align-self: center;
			h3 {
				color: $primary;
				margin: 3% 0%;
				@media only screen and (max-width: 400px) {
					margin: 10% 0%;
				}
			}
			p {
				color: grey;
				font-size: 17px;
				line-height: 20px;
			}
		}
	}
}
