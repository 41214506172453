.signup-container{
    margin-top: -7%;
    .signup{
        padding-top: 10%;
        background-image:url("../../images/exam.jpg");
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
        height: 100vh;
       
        display: grid;
        place-content:center;
        .signup-form{
            padding: 5%;
            background-color: rgba(255,255,255,0.7);
            border-radius: 10px;
            .text{
                text-align: center;
                h1{
                    color: $primary;
                }
                p{
                    color: grey;
                }

            }

            form{
                display: grid;
                justify-content: center;
                @media only screen and (max-width: 400px) {
                    justify-content: start;
                 }
                
                .form-control{
                    padding: .8em;
                    margin:2% 0%;
                    border: 2px solid $pwhite;
                    border-radius: 10px;
                    background-color: $pwhite;
                    outline: none;
                    width: 100%;
                    @media only screen and (max-width: 400px) {
                        width: 90%;
                     }
                   
                }
                .signup-type{
                    justify-self: center;
                    display: flex;
                    .bb{
                        margin: 2% 0%;
                        justify-self: start;
                        border: none;
                        font-size: 18px;
                        padding: .6rem .5rem;
                        background-color: $pwhite;
                        color: $secondary;
                        cursor: pointer;
                    }
                    .active{
                        background-color: $primary;
                    }
                }
                .signin-link{
                    text-decoration: none;
                    cursor: pointer;
                    color: $primary;
                    transition: all ease-in-out .3s;
                    &:hover{
                        transition: all ease-in-out .3s;
                        color: $secondary;

                    }
                }
                .btn-container {
                    display: grid;
                    .btn-signup{
                        margin: 2% 0%;
                        justify-self: center;
                        border: none;
                        font-size: 18px;
                        padding: .6rem 2rem;
                        border-radius: 30px;
                        background-color: $primary;
                        color: white;
                        transition: all ease .3s;
                        cursor: pointer;&:hover{
                            transition: all ease .3s;
                            background-color: $secondary;
                        }
                    }
                    .bot {
                        place-self: center;
                        margin: 0% 50%;
                        border: 10px solid $secondary;
                        border-radius: 50%;
                        border-top: 10px solid $primary;
                        width: 6%;
                        height: 6%;
                        -webkit-animation: spin 2s linear infinite;
                        animation: spin 2s linear infinite;
                      }
                }
                
            }
        }
    }
}