.details{
    display: grid;
    grid-template-columns: 1fr 1fr;
    border: 1px solid $primary ;
    padding:2% 3%;
    background-color: rgba(218, 216, 216, 0.37);
    border-radius: 10px;
    @media only screen and (max-width: 600px) {
        grid-template-columns: 1fr;
    }
    .detail{
        text-transform: uppercase;
    }
    .email{
        text-transform: lowercase;
    }
}
.bio{
    border: 1px solid $primary ;
    padding:2% 3%;
    background-color: rgba(218, 216, 216, 0.37);
    border-radius: 10px;
    width: 50%;
    text-transform: uppercase;
    overflow-wrap: break-word;
    @media only screen and (max-width: 600px) {
        width: 100%;
    }
    

}
.overview{
    border: 1px solid $primary ;
    padding:2% 3%;
    background-color: rgba(218, 216, 216, 0.37);
    border-radius: 10px;
    width: 100%;
    text-transform: uppercase;
    overflow-wrap: break-word;
    word-break: break-all;
    margin: 1% 0%;
    p{
        margin: 2% 0%;
    }
    .btn-complete{
        padding: 2% 3%;
        background-color: $secondary;
        color: white;
        border:none;
        cursor: pointer;
        &:hover{
            background-color: $primary;
        }
    }

}
.overview-subject{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3%;
    margin-bottom: 10%;
    @media only screen and (max-width: 600px) {
        grid-template-columns: 1fr;
        margin-bottom: 60%;
    }
}