.contact-container {
	margin-top: -8%;
	z-index: -1;
	.contact-top {
		background-image: url("../../images/contact.jpg");
		background-repeat: no-repeat;
		background-position: top;
		background-size: cover;
		height: 90vh;
		z-index: -1;
		text-align: center;
		div {
			background-color: rgba(5, 5, 5, 0.151);
			height: 100%;
		}

		h1 {
			padding-top: 9em;
			color: $pwhite;
		}
		p {
			color: rgb(216, 216, 216);
			word-spacing: 10px;
			font-size: 18px;
			a {
				text-decoration: none;
				color: rgb(216, 216, 216);
			}
			span {
				word-spacing: 0px;
			}
		}
	}

	.contact-body-1 {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 5%;
		@media only screen and (max-width: 800px) {
			grid-template-columns: 1fr;
			gap: 0%;
		}
		.body-1-left {
			background-color: $secondary;
			h1 {
				@media only screen and (max-width: 400px) {
					text-align: center;
				}
			}
			form {
				display: grid;
				margin: 2% 5%;
				@media only screen and (max-width: 800px) {
					margin: 2% 2%;
				}

				.enquiry-btn {
					margin: 2% 0%;
					justify-self: start;
					border: none;
					font-size: 18px;
					padding: 0.6rem 1rem;
					border-radius: 30px;
					background-color: $primary;
					color: white;
					transition: all ease 0.3s;
					cursor: pointer;
					&:hover {
						transition: all ease 0.3s;
						color: $secondary;
						background-color: $pwhite;
					}
				}
				#message {
					resize: none;
				}
				.form-control {
					padding: 1em;
					margin: 1% 0%;
					border: 2px solid $pwhite;
					border-radius: 10px;
					background-color: transparent;
					outline: none;
					color: $pwhite;
					transition: all ease 0.5s;
					&:focus {
						color: $secondary;
						transition: all ease 0.5s;
						background-color: $pwhite;
					}
				}
				.name-email {
					display: grid;
					grid-template-columns: 1fr 1fr;
					gap: 2%;

					@media only screen and (max-width: 400px) {
						grid-template-columns: 1fr;
					}
				}
			}

			h1,
			h3 {
				color: $primary;
				margin: 0% 5%;
				padding-top: 15%;
				font-size: 45px;
			}
		}
		.body-1-right {
			padding-top: 15%;
			@media only screen and (max-width: 800px) {
				padding-top: 5%;
				margin-bottom: 10%;
			}

			.right-text {
				color: grey;
				text-align: center;
				padding: 0% 10%;
			}
			.contact {
				display: grid;
				grid-template-columns: 1fr 1fr;
				@media only screen and (max-width: 400px) {
					grid-template-columns: 1fr;
				}
				.contact-info {
					text-align: center;
					margin-top: 8%;
					.icon {
						color: $primary;
						text-align: center;
						span {
							font-size: 40px;
						}
					}

					h3 {
						color: $secondary;
					}
					p {
						color: grey;
					}
					.social {
						display: flex;
						justify-content: center;
						gap: 5%;
						font-size: 20px;
						a {
							color: $primary;
							&:hover {
								color: $secondary;
							}
						}
					}
				}
			}
		}
	}
}
