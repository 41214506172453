// VARIABLES

$primary:#fa5508;
$secondary:#0f1b5f;
$pwhite: #FDFFFC;

* {
  font-family: "Thasadith";
  box-sizing: border-box;
  padding: 0px;
  margin:0px;
}
#loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid rgb(255, 87, 34);
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@import './header/header.scss';
@import './landing/slide/slide.scss';
@import './landing/we/weare.scss';
@import './landing/category/category.scss';
@import './landing/services/services.scss';
@import './landing/faq/faq.scss';
@import './footer/footer.scss';
@import './about/about.scss';
@import './contact/contact.scss';
@import './signup/signup.scss';
@import './signin/signin.scss';
@import './category/categories.scss';
@import './dashboard/dashboard.scss';
@import './admin/adminsignin.scss';
@import './admin/adapproved.scss';
@import './reset/reset.scss';
@import './admin/adviewinfo.scss';
@import './admin/adtutorsearch.scss';
@import './privacy/privacy.scss';