.cat-container{
    .cat-content{
        .cat-button{
            h2{
                text-align: center;
                padding: .8rem;
                background-color: $primary;
                border-radius: 10px;
                color: $pwhite;
                text-transform: uppercase;

            }
        }
        .cat-body{
            margin: 5% 2%;
            display: grid;
            grid-template-columns: repeat(3,1fr);
            gap: 2%;
            h2{
                color: $primary;
            }
            
            @media only screen and (max-width: 700px) {
                grid-template-columns: 1fr 1fr;
                padding-bottom: 45%;
             }
             @media only screen and (max-width: 400px) {
                grid-template-columns: 1fr;
                
             }
            .card{
                
                .image{
                    
                    img{
                        width: 100%;
                        
                        
                    }
                   
                }
            }
        }
    }
}