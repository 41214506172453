.details{
    margin: 2% 0%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    border: 1px solid $primary ;
    padding:2% 3%;
    background-color: rgba(218, 216, 216, 0.37);
    border-radius: 10px;
    @media only screen and (max-width: 600px) {
        grid-template-columns: 1fr;
    }
    .detail{
        margin: 2% 0%;
        text-transform: uppercase;
    }
    .email{
        text-transform: lowercase;
    }
}
.bio{
    margin: 2% 0%;
    border: 1px solid $primary ;
    padding:2% 3%;
    background-color: rgba(218, 216, 216, 0.37);
    border-radius: 10px;
    width: 50%;
    text-transform: uppercase;
    overflow-wrap: break-word;
    

}
.overview{
    border: 1px solid $primary ;
    padding:2% 3%;
    background-color: rgba(218, 216, 216, 0.37);
    border-radius: 10px;
    width: 50%;
    text-transform: uppercase;
    overflow-wrap: break-word;
    margin-bottom: 2%;

}