.hibasic-container{
    .form-container{
        margin: 3% 0%;
        .form-control{
            padding: 3%;
            background-color: #F0ECEC;
            border: none;
            border-radius: 10px;
            outline: none;
            transition: all ease .3s;
            cursor: pointer;
            .date{
                width: 90%;
                background-color: #F0ECEC;
                border: none;
                outline: none;
                cursor: pointer;
            }
            &:focus{
                transition: all ease .3s;
                background-color: $pwhite;
                border: 1px solid $secondary;
            }
        }
        .state-lga,.gender-dob,.bio-container{
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap:3%;
            margin:3% 0%;
            @media only screen and (max-width: 600px) {
                grid-template-columns: 1fr;
                margin: 5% 0%;
            }
        }
        #bio{
            background-color: #F0ECEC;
            border: none;
            border-radius: 10px;
            resize: none;
            padding: 3%;
            outline: none; 
            transition: all ease .3s;   
            cursor: pointer;
            &:focus{
                transition: all ease .3s;
                background-color: $pwhite;
                border: 1px solid $secondary;
            }
            
        }
        .btn-container{
            .btn-submit{
                padding: 1% 2.5%;
                font-size: 18px;
                background-color: $primary;
                border: none;
                cursor: pointer;
                color: $pwhite;
                transition: all ease .3s;
                &:hover{
                    transition: all ease .3s;
                    background-color: $secondary;

                }
            }
            
        }
    }
   
    .hide{
        display: none;
    }
}